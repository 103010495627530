import React, { useRef } from "react";
import { HeadProps, PageProps } from "gatsby";
// Components
import { Layout } from "components/Layout";
import Page, { PageSidebar, PageTitle } from "components/Page";
import HTMLContent from "components/HTMLContent";
import HeadTags from "components/Head";
// Types
import { CategoryContext } from "types/PageContext";

type DataProps = {};

export const GeneratedCategoryTemplate: React.FC<PageProps<
  DataProps,
  CategoryContext
>> = ({ pageContext }) => {
  const { title, slug } = pageContext;
  const htmlContentRef = useRef<HTMLDivElement>(null);
  return (
    <Layout title={title} slug={slug}>
      <Page id={slug}>
        <PageTitle>{title}</PageTitle>
        <HTMLContent ref={htmlContentRef}>
          {`<p>Sorry, this Category is Currently Unavailable</p>`}
        </HTMLContent>
      </Page>
      <PageSidebar contentRef={htmlContentRef} />
    </Layout>
  );
};

export const Head = (props: HeadProps<DataProps, CategoryContext>) => (
  <HeadTags {...props} />
);

export default GeneratedCategoryTemplate;
